/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.legend-wrapper {
	background: #fff;
	height: 100%;
	padding: 1rem 1rem 1rem 1rem;
	border-right: solid 2px rgb(144, 144, 144);
	overflow-y: scroll;
	.title {
		text-transform: capitalize;
		font-size: 16px;
		font-weight: 900;
		display: flex;
		align-items: center;
		svg {
			height: 28px;
			width: 28px;
			margin-right: 0.5rem;
		}
	}

	.item {
		margin-top: 0.5rem;
		display: flex;
		align-items: center;
		border-radius: 16px;
		min-height: 33px;
		padding-left: 3px;
	}

	.item:hover {
		cursor: pointer;
		background: lightgrey;
	}

	.item:last-child {
		margin-bottom: 2rem;
	}

	.order {
		width: 28px;
		height: 28px;
		padding-top: 2px;
		font-weight: 900;
		font-size: 16px;
	}

	.description-wrapper {
		display: flex;
		flex-direction: column;
		margin-left: 1rem;
	}
	.description {
		font-weight: 900;
		font-size: 12px;
	}
	.timeline {
		font-weight: 300;
		font-size: 12px;
	}
	.budget {
		font-weight: 600;
	}
}