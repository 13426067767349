.region-select {
  display: flex;
  align-items: center;
	margin-left: auto;

  .MuiInputBase-root {
  background: white;
  min-width: 250px;

    .region-select-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      height: 30px;
      cursor: pointer;
    }
  }

  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
