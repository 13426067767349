.config {
  width: 100%;
  height: calc(100vh - var(--topbar-height) - var(--project-header-height));
  background-color: white;
}

.MuiDataGrid-main:last-child {
  display: none !important;
  color: transparent !important;
}

.MuiDataGrid-main:last-of-type {
  display: none !important;
  color: transparent !important;
}

.MuiDataGrid-virtualScrollerRenderZone {
  position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 200013;
    background: white;
}

.MuiDataGrid-virtualScroller {
  z-index: 200013 !important;
  background: white;
}

.MuiPopper-root {
  z-index: 200014 !important;
}
