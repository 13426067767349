.card-project {
  position: relative;
  padding: 24px;
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border-radius: 8px;
  border: 1px solid #F4F3F7;
  background: #FFF;

  > h2 {
    color: #030E18;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin-bottom: 7px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 90%;
  }

  > h3 {
    color: #303383;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 5px;
    border-radius: 14.5px;
    background-color: rgba(103, 28, 202, 0.10);
    align-self: flex-start;
    padding: 0 11px;
  }

  > p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #4B4B4B;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 5px;
  }

  > span {
    color: #B7B7B7;
    font-family: Avenir;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }

  > .simple-dropdown {
    position: absolute;
    top: 22px;
    right: 24px;
  }
}