.project-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--project-header-height);
  background: #F4F3F7;

  .icon-action {
    width: var(--project-header-height);
    height: 56px;
    cursor: pointer;
    svg * {
      fill: #303383;
    }
  
    &.active, &:hover {
      background-color: #303383;
      
      svg * {
        fill: white;
      }
    }
  }

  > button {
    height: 40px;
    margin-left: 5px;
  }
}