header {
  background-color: #2F2F2F;
  height: var(--topbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 24px 0 0;
  display: flex;
  align-items: center;

  *, .MuiButtonBase-root {
    color: white;
  }

  .MuiButtonBase-root {
    font-weight: 800;
  }

  .logos {
    display: flex;
    gap: 28px;
  }

  > p {
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  header .logos {
    display: none;
  }
}