button.burger {
  background-color: transparent;
  border: none;
  outline: none;
  height: var(--topbar-height);
  width: 104px;
  cursor: pointer;
  margin-right: 17px;
  transition: all 0.15s ease-in-out;

  > svg {
    transform: scale(0.27) translateY(-10px);

    .line {
      fill: none;
      stroke: rgb(255, 255, 255);
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1, .line3 {
      stroke-dasharray: 60 207;
    }

    .line2 {
      stroke-dasharray: 60 60;
    }
  }

  &.opened {
    background-color: #161616;

    .line1, .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }

    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }
  }
}