$hover-color: #1a1b45;
$menu-width: 56px;
$menu-height: 56px;

#map-menu {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  width: 56px;
  transition: 300ms;

  .icon-wrapper {
    &:first-of-type {
      border-radius: 4px 0px 0px 0px;
    }

    background-color: #303383;
    height: $menu-height;
    width: $menu-width;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $hover-color;
      cursor: pointer;
    }

    svg {
      color: white;
    }
  }

  &.display-menu {
    right: 320px;
  }

  .navigation-control {
    .mapboxgl-ctrl-group {
      border-radius: 0px 0px 0px 4px;
      box-shadow: none;
      background: #303383;
      width: $menu-width;
      height: $menu-height;

      &:hover {
        background-color: $hover-color;
        cursor: pointer;
      }
    }
    .mapboxgl-ctrl button.mapboxgl-ctrl-compass {
      width: $menu-width;
      height: $menu-height;
      &:hover {
        background-color: none;
        cursor: inherit;
      }

      .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23f44336'/%3E%3C/svg%3E");
      }
    }
  }
}

.toolbar-menu {
  background-color: #F4F3F7;
  position: absolute;
  transition: 300ms;
  height: 100%;
  width: 320px;
  top: 0;
  right: -320px;
  z-index: 2;
  cursor: default;
  padding: 24px 20px 20px 20px;
  overflow-y: auto;

  &.display {
    right: 0;
  }

  .MuiCheckbox-root.Mui-checked {
    color: var(--color-theme-select);
  }

  .title {
    font-size: 12px;
    font-weight: 400;
  }
}
