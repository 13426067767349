#projects {
  .actions {
    display: flex;
    margin-bottom: 24px;
    gap: 24px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 25.5px;
  }
}
