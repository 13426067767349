.editor {
  height: calc(100vh - var(--topbar-height) - var(--project-header-height));
  position: relative;
  
  .monaco-editor {
    padding-top: 5px;
  }

  .minimap {
    display: none;
    width: 0;
  }

  .button-toggle-panel {
    position: absolute;
    right: -40px;
    z-index: 4;
    background: rgb(144, 144, 144);
    height: 80px;
    display: flex;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    top: 43%
	}
}

.panel {
  width: 500px;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.panel-close {
  width: 0px;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
}