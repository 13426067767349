* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #app {
      overflow: auto;
      height: calc(100vh - var(--topbar-height));
      margin-top: var(--topbar-height);
    }
  }
}

.pages {
  padding: 33px 24px 33px 66px;
  transition: all 0.15s ease-in-out;

  &.side-menu-opened {
    padding-left: calc(var(--side-menu-width) + 66px);
  }
}

@media screen and (max-width: 768px) {
  .pages {
    &.side-menu-opened {
      padding-left: calc(var(--side-menu-width-mobile) + 20px);
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiDataGrid-main:last-child {
  display: none !important;
  color: transparent !important;
}

.MuiDataGrid-main:last-of-type {
  display: none !important;
  color: transparent !important;
}
