@mixin dropdown-modifier($class) {
  &.dropdown--#{$class} {
    height: 47px;
    border-radius: 6px;
    border: 1px solid #F4F3F7;
    padding: 15px 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 220px;

    > span {
      color: #969696;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
    }
  }
};

.simple-dropdown {
  .drop-down-button {
    background: none;
    border: none;
    color: #C6C6C6;
    cursor: pointer;
  }

  @include dropdown-modifier(chevron);
}



 