.mapboxgl-hover-custom-popup {
  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 1rem;
    width: 300px;
    span {
      font-weight: 900;
    }
    .mapbox-popup-title {
      padding: 5px 30px 5px 10px;
      font-size: 0.8em;
      color: white;
      background: black;
      font-weight: 900;
    }
    .mapboxgl-popup-close-button {
      font-size: large;
      position: absolute;
      right: 10px;
      top: 0;
      border: 0;
      border-radius: 0 3px 0 0;
      cursor: pointer;
      background-color: transparent;
      color: white;
    }
    .popup-content {
      padding: 20px 10px 10px 10px;
      background: black;
      border-radius: 1rem;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 900;
    display: flex;
    align-items: center;
    svg {
      height: 28px;
      width: 28px;
      margin-right: 0.5rem;
    }
  }
  
  .item {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 16px;
    min-height: 33px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .order {
    width: 28px;
    height: 28px;
    padding-top: 2px;
    font-weight: 900;
    font-size: 16px;
  }
  
  .description-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
  }
  .description {
    font-weight: 900;
    font-size: 12px;
  }
  .timeline {
    font-weight: 300;
    font-size: 12px;
  }
  .budget {
    font-weight: 600;
  }
}
