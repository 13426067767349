@mixin button-modifier($color, $fontColor, $borderColor, $class) {
  &.button--#{$class} {
    background: $color;
    color: $fontColor;
    border: 1px solid $borderColor;

    &:hover {
      background: darken($color, 5%);
    }
  }
};

.MuiButtonBase-root.simple-button {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: unset;
  text-transform: unset;
  border-radius: 6px;

  &:hover {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  @include button-modifier(#303383, #FFF, transparent, primary);
  @include button-modifier(#FFF, #303383, #303383, light);
}